// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-with-sidebar-js": () => import("../src/templates/WithSidebar.js" /* webpackChunkName: "component---src-templates-with-sidebar-js" */),
  "component---src-templates-loan-compare-main-page-js": () => import("../src/templates/LoanCompareMainPage.js" /* webpackChunkName: "component---src-templates-loan-compare-main-page-js" */),
  "component---src-templates-loan-compare-js": () => import("../src/templates/LoanCompare.js" /* webpackChunkName: "component---src-templates-loan-compare-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

